import { RouteLocationNormalized } from 'vue-router'

import { AuthenticationClientOptions } from '@ankor-io/commodore/src/iam/types'
import { AuthenticationContext, IAMPredicates } from '@ankor-io/common/auth/client/types'
import { JwtPayload, getJwtPayload } from '@ankor-io/common/auth/jwt'

/**
 * Create the Router Guards to use in the router.
 *
 * @param authenticationContext the authentication context to check authentication against.
 * @param authenticationClientOptions the authentication client options.
 * @returns an IAMPredicates of methods aligning to the corresponding router guards
 */
export const iamPredicates = (
  authenticationContext: AuthenticationContext,
  authenticationClientOptions: AuthenticationClientOptions,
): IAMPredicates => {
  //
  const beforeEach = async (to: RouteLocationNormalized) => {
    // see: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards

    // check this route was marked as skip authentication.
    if (to.matched.some((record) => record.meta.skipAuthentication)) {
      console.debug('>>> authentication check skipped.')
      return true
    }

    //
    let token
    try {
      token = await authenticationContext.getToken()

      if (token === undefined) {
        throw new Error('undefined token response')
      }

      if (to.matched.some((record) => record.meta.toRegistration)) {
        // this situation happens when the user tries to go to /register when they already have access
        // For this case, we simply redirect them to the home page
        return { path: '/' }
      }
    } catch (e: any) {
      // If the user is not authenticated, we redirect them to register or login page.

      /*
       ========================= NOTE =============================================
       In theory this else condition should NOT run for the majority of use cases.
       This is because one of the first things we do when we initiaze the app (inside main.ts), is to get the authentication context
       and user token. If the user is not authenticated, we redirect them to login or register there.

       By the time we reach the route guard, the user would have already gone through the authentication flow.

        We should not remove this code either as there are some edge cases where this code is needed. One example being:
          1. user remains inactive for a period of time (long enough for token to expire)
          2. user then tries to navigate to a different route without refreshing browser

      =============================================================================
      */

      if (to.matched.some((record) => record.meta.toRegistration)) {
        console.debug('>>> redirect to registration page.')
        await authenticationContext.redirectToRegister?.()
        return false
      }
      // log it.
      console.error(`The call to get the token has failed, redirecting to login: ${e}`)
      // as some error occured (typically: the token is expired and the lib upchucks)
      // we should redirect back to authenticate!
      await authenticationContext.redirectToLogin()
      return false
    }

    // verify if the token has valid authorization
    // with permission to access commodore and is part of the organization
    const jwtPayload: JwtPayload = getJwtPayload(token)

    // authenticate the user, when they belong to default organization
    if (jwtPayload.org_code === authenticationClientOptions.default_org_code) {
      return true
    }

    if (jwtPayload.org_code !== authenticationClientOptions.org_code) {
      /**
       * The user is not part of the organization, logout the user
       */
      await authenticationContext.logout()
      return false
    }
    // auth ok -- continue with the route.
    return true
  }

  //
  return {
    beforeEach,
  }
}
