import { KindeClient } from '@kinde-oss/kinde-auth-pkce-js'

import { AuthenticationContext } from './types'

/**
 * Get a token from the KindeClient or redirect to login when failing to get the token from the client.
 *
 * @param kindeClient the kinde client to get the token from.
 * @param authenticationContext the authentication context to trigger the redirect for
 */
export const getTokenOrRedirect = async (
  kindeClient: KindeClient,
  authenticationContext: AuthenticationContext,
  isRegisterPath?: boolean,
): Promise<string | undefined> => {
  let _token
  try {
    _token = await kindeClient.getToken()

    if (_token === undefined) {
      throw new Error('undefined token response')
    }
    return _token
  } catch (e: any) {
    // log it.
    console.error(`The call to get the token has failed, redirecting to login: ${e}`)
    // as some error occured (typically: the token is expired and the lib upchucks)
    // we should redirect back to authenticate!
    if (isRegisterPath && authenticationContext.redirectToRegister) {
      await authenticationContext.redirectToRegister()
      return undefined
    }
    await authenticationContext.redirectToLogin()
    return undefined
  }
}
