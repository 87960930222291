import { GrowthBook } from '@growthbook/growthbook'

export class GrowthBookProvider {
  private static instance: () => GrowthBook

  public static setInstance(instance: () => GrowthBook) {
    this.instance = instance
  }

  public static getInstance(): () => GrowthBook {
    return this.instance
  }
}
